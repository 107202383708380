import { EventAction } from './model';

export const getFormattedPercent = (percent?: number): EventAction | undefined => {
    if (!percent) {
        return;
    }

    if (percent >= 25 && percent <= 50) {
        return 'Played video: 25 percent';
    }

    if (percent >= 50 && percent <= 75) {
        return 'Played video: 50 percent';
    }

    if (percent >= 75 && percent <= 99) {
        return 'Played video: 75 percent';
    }

    if (percent >= 99) {
        return 'Completed video';
    }
};
