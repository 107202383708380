import styled from '@emotion/styled';

export const StyledConsentPlaceholder = styled.div(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: '3em',
    background: theme.colors.dark70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledConsentPlaceholderContent = styled.div({
    maxWidth: '50ch',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',
    alignItems: 'center',
    justifyContent: 'center',
});
