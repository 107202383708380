import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useGtm } from '~/shared/utils';
import { Action, ProgressEventAction, State } from './model';
import { getFormattedPercent } from './utils';

const reducer = (state: Partial<State>, action: Action): Partial<State> => {
    switch (action.type) {
        case 'play':
            return {
                ...state,
                ...action.payload,
                eventAction: 'Started video',
            };
        case 'pause':
            return {
                ...state,
                ...action.payload,
                eventAction: 'Paused video',
            };
        case 'complete':
            return {
                ...state,
                ...action.payload,
                eventAction: 'Completed video',
            };
        case 'skip':
            return {
                ...state,
                ...action.payload,
                eventAction: 'Skipped video forward or backwards',
            };
        case 'progress':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const useVideoTracking = () => {
    const progressEventActionRef = useRef<ProgressEventAction>();
    const { trackVideo } = useGtm();
    const [state, dispatch] = useReducer(reducer, {});

    const dispatchProgress = useCallback((progress: number) => {
        const formattedEventAction = getFormattedPercent(progress * 100) as ProgressEventAction;

        if (formattedEventAction && formattedEventAction !== progressEventActionRef.current) {
            progressEventActionRef.current = formattedEventAction;
            dispatch({ type: 'progress', payload: { eventAction: formattedEventAction } });
        }
    }, []);

    const trackState = useCallback(() => {
        const { eventAction, eventLabel } = state;
        if (eventAction && eventLabel) {
            trackVideo(eventAction, eventLabel);
        }
    }, [state, trackVideo]);

    useEffect(() => {
        if (state.eventAction) {
            trackState();
        }
    }, [state.eventAction, trackState]);

    return { dispatch, dispatchProgress };
};
