import { memo } from 'react';
import { CookieConsentSettings } from '~/shared/hooks/useCookieConsent';
import { Button, Text, useCookieInformation } from '~/shared/components';
import { StyledConsentPlaceholder, StyledConsentPlaceholderContent } from '../styled';
import { useTranslation } from '~/shared/utils/translation';
import { TranslationKey } from '~/lib/data-contract';
import { TextBlock } from '~/shared/components/TextBlock';

export const ConsentPlaceholder = memo((types: CookieConsentSettings) => {
    const { translate } = useTranslation();
    const { renewConsent } = useCookieInformation();

    return (
        <StyledConsentPlaceholder>
            <StyledConsentPlaceholderContent>
                <TextBlock shade="paragraphInverted">
                    <Text
                        children={translate('global.consent.placeholder.headline')}
                        variant="display3"
                        shade="paragraphInverted"
                        as="h2"
                    />
                    <ul>
                        {Object.keys(types).map((name) => (
                            <li style={{ textTransform: 'capitalize' }} key={name}>
                                {translate(`global.consent.type.${name}` as TranslationKey)}
                            </li>
                        ))}
                    </ul>
                </TextBlock>
                <Button variant="primary" shade="light" onClick={renewConsent}>
                    {translate('global.consent.placeholder.button')}
                </Button>
            </StyledConsentPlaceholderContent>
        </StyledConsentPlaceholder>
    );
});
