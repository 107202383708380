import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { ifProp } from 'styled-tools';
import { cover, shouldNotForwardProps } from '~/shared/utils/styled';
import { Button } from '../Button';

interface StyledVideoContainerProps {
    cover?: boolean;
    paddingTop?: CSSProperties['paddingTop'];
}
interface StyledVideoOverlayProps {
    loading: boolean;
    playing?: boolean;
    buttonPosition?: 'center' | 'corner';
}

export const StyledVideo = styled.div<StyledVideoContainerProps>(
    {
        width: '100%',
        overflow: 'hidden',
    },
    ifProp('cover', {
        height: '100%',
    })
);

export const StyledVideoAspect = styled.div({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    width: '100%',
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});

export const StyledVideoContainer = styled.div<StyledVideoContainerProps>(
    ({ paddingTop }) => ({
        position: 'relative',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: paddingTop,
        // To accommodate sneaky black sub-pixel borders caused by
        // the embedded youtube/vimeo player / our calculated aspect ratio.
        width: 'calc(100% + 2px)',
        transform: 'translate(-1px, 0)',
        clipPath: 'inset(0px 1px)',
    }),
    ifProp('cover', {
        height: 'calc(100% + 2px)',
        transform: 'translate(-1px, -1px)',
        clipPath: 'inset(1px 1px)',
    })
);

export const StyledVideoButton = styled(Button)({
    pointerEvents: 'all',
});

export const StyledVideoOverlay = styled('div', {
    shouldForwardProp: shouldNotForwardProps(['loading']),
})<StyledVideoOverlayProps>(({ playing, loading, buttonPosition }) => ({
    ...cover,
    pointerEvents: 'auto',
    cursor: loading ? 'progress' : 'pointer',
    display: 'flex',
    alignItems: buttonPosition === 'corner' || playing ? 'flex-end' : 'center',
    justifyContent: buttonPosition === 'corner' || playing ? 'flex-start' : 'center',
    opacity: playing ? 0 : 1,

    '&:hover': {
        opacity: 1,
    },
}));
