import { useCallback, useEffect, useState } from 'react';

type VideoMetadata = {
    aspectRatio: number;
    thumbnailSrc: string;
    thumbnailHeight: number;
    thumbnailWidth: number;
};

type UseVideoMetadata = {
    metadata: VideoMetadata | null;
    loading: boolean;
};

type NoEmbedResponse = {
    thumbnail_url: string;
    thumbnail_height: number;
    thumbnail_width: number;
    width: number;
    height: number;
};

const requestCache: { [key: string]: Promise<NoEmbedResponse> } = {};

export const fetchVideoMetadata = async (src: string): Promise<VideoMetadata> => {
    let request = requestCache[src];

    if (!request) {
        request = fetch(`https://noembed.com/embed?url=${src}`).then((r) => r.json());
        requestCache[src] = request;
    }

    const { thumbnail_url, thumbnail_height, thumbnail_width, width, height } = await request;

    return {
        thumbnailSrc: thumbnail_url,
        thumbnailHeight: thumbnail_height,
        thumbnailWidth: thumbnail_width,
        aspectRatio: height / width,
    };
};

export const useVideoMetadata = (url: string): UseVideoMetadata => {
    const [metadata, setMetadata] = useState<VideoMetadata | null>(null);
    const [loading, setLoading] = useState(true);

    const updateMetadata = useCallback(async () => {
        setLoading(true);
        await fetchVideoMetadata(url).then(setMetadata);
        setLoading(false);
    }, [url]);

    useEffect(() => {
        updateMetadata();
    }, [updateMetadata, url]);

    return {
        metadata,
        loading,
    };
};
